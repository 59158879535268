<template>
    <div class="st-fadein-content">
        <table class="table table-hover table-bordered table-striped table-sm" style="margin-bottom: 0;">
            <thead>
            <tr>
                <template v-for="(field,index) in fields">
                    <template v-if="field.showintable && field.type === 'ID'">
                        <th :key="'crudheader-' + field.name + '-' + index" class="pt-1 pl-2" style="width: 30px;">
                            <input type="checkbox" :id="field.name+'-'+data.id" v-model="selectAll"
                                   v-on:change="selectAllRecords"/>
                        </th>
                    </template>
                    <template v-else>
                        <CrudTableHeader :key="field.name+'-'+field.id" :field="field" :pageInfo="pageInfo"
                                         v-on:toggle-sort="toggleSort"
                                         v-on:toggle-stateintable="$emit('toggle-stateintable', $event)"
                                         v-if="field.showintable"/>
                    </template>
                </template>
                <th style="text-align: center" v-if="showActions">
                    <font-awesome-icon :icon="['fas', 'cogs']" size="sm"/>
                    {{ $i18n.t('translations.Actions') }}
                </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(row,index) in data">
                <tr :key="'tr-'+row.id+'-'+reloadRow">
                    <template v-for="(field,fieldIndex) in fields">
                        <template v-if="field.showintable">
                            <template v-if="field.type === 'ID'">
                                <td :key="'td-' + fieldIndex + '-' + row.id" class="pt-1 pl-2"
                                    :class="field.tdclassfield ? row[field.tdclassfield] : field.tdclass"
                                    :style="field.tdstylefield ? row[field.tdstylefield] : field.tdstyle"
                                    :title="fieldTitle(field, row)">
                                    <input type="checkbox" :id="field.name+'-'+row.id" v-model="row.selected"
                                           v-on:change="checkIfRowSelected"/>
                                </td>
                            </template>
                            <template v-else>
                                <td :key="'td-' + fieldIndex + '-' + row.id"
                                    :class="field.tdclassfield ? row[field.tdclassfield] : field.tdclass"
                                    :style="field.tdstylefield ? row[field.tdstylefield] : field.tdstyle"
                                    :title="fieldTitle(field, row)">
                                    <Field :field="field" :data="row" :state="state"
                                           v-on:inline-edit="inlineEdit(index, $event)"/>
                                </td>
                            </template>
                        </template>
                    </template>
                    <td style="text-align: center;" :style="actionsColumnStyle" v-if="showActions">
                        <template v-for="(action,actionIndex) in actions">
                            <template v-if="action.placement === 'TABLEROW' && !action.hideintable && (!action.hidefield || !row[action.hidefield])">
                                <ButtonAction :key="'action-' + '-' + actionIndex + '-' + row.id" forward-events
                                              :action="action" :disabled="action.disabled || (action.disablefield && row[action.disablefield])"
                                              v-on:forward-event="handleForwardedRowEvent($event, row)"
                                              v-if="action.type === 'BUTTON'"/>
                                <RouteAction :key="'action-' + '-' + actionIndex + '-' + row.id"
                                             :action="action" :disabled="action.disabled || (action.disablefield && row[action.disablefield])" :data="row"
                                             v-if="action.type === 'ROUTE'"/>
                            </template>
                        </template>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>

        <div class="container-fluid mt-3 p-0">
            <div class="row">
                <div class="col">
                    <template v-for="(action,index) in actions">
                        <DropDownAction :key="'crudfooter-' + action.name + '-' + index"
                                        :action="action" :disabled="noRowsSelected(action)" forward-events
                                        v-on:forward-event="handleForwardedEvent(action, $event)"
                                        v-if="action.type === 'DROPDOWN' && action.placement === 'TABLEFOOTERLEFT'"/>
                    </template>
                </div>
                <div class="col-auto">
                    <template v-for="(action,index) in actions">
                        <DropDownAction :key="'crudfooter-' + action.name + '-' + index"
                                        :action="action" :disabled="noRowsSelected(action)" forward-events
                                        v-on:forward-event="handleForwardedEvent(action, $event)"
                                        v-if="action.type === 'DROPDOWN' && action.placement === 'TABLEFOOTERRIGHT'"/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction.vue";
import CrudTableHeader from "./CrudTableHeader";
import DropDownAction from "@/components/actions/DropDownAction";
import RouteAction from "@/components/actions/RouteAction.vue";
import Field from "@/components/fields/Field.vue";
import FieldTitle from "@/mixins/FieldTitle";

export default {
    name: "CrudTable",
    components: {
        ButtonAction,
        CrudTableHeader,
        DropDownAction,
        Field,
        RouteAction,
    },
    props: ["fields", "data", "actions", "pageInfo", "state"],
    mixins: [FieldTitle],
    data() {
        return {
            rowSelected: false,
            showActions: false,
            selectAll: false,
            reloadRow: 0,
        }
    },
    computed: {
        actionsColumnStyle() {
            const actions = this.actions.filter((a) => a.placement === 'TABLEROW' && (!a.hidefield || !this.data[a.hidefield])).length;
            const width = 20 + actions * 29;
            return 'width:' + width + 'px';
        },
    },
    created() {
        this.checkLayout();
    },
    methods: {
        checkIfRowSelected() {
            this.rowSelected = false;
            this.data.forEach(row => {
                if (row.selected) {
                    this.rowSelected = true;
                }
            });
        },
        checkLayout() {
            let show = false;
            this.actions.forEach(action => {
                if (!action.hideintable && action.placement === 'TABLEROW') {
                    show = true;
                }
            });
            this.showActions = show;
        },
        handleForwardedEvent(action, eventData) {
            let ids = [];
            this.data.forEach(row => {
                if (row.selected) {
                    ids.push(row.id);
                }
            });
            eventData.data.ids = ids;
            this.$emit(eventData.event, eventData.data);
        },
        handleForwardedRowEvent(eventData, row) {
            this.$emit(eventData.event, row);
        },
        inlineEdit(index, data) {
            this.data[index] = data.data;
            this.reloadRow++;
            this.state.loading = false;
            this.$emit('inline-edit', data);
        },
        noRowsSelected(action) {
            if (action.enable === 'selection') {
                return !this.rowSelected;
            }
            return false;
        },
        toggleSort(name) {
            this.$emit('toggle-sort', name);
        },
        selectAllRecords() {
            this.data.filter(row => row.selected = this.selectAll);
            this.checkIfRowSelected();
        }
    }
}
</script>

<style scoped>
.btn.disabled, .btn:disabled {
    opacity: 0.15;
}
</style>
