<template>
    <th v-if="field.type === 'ID' || field.unsortable" :style="styleObject">
        <font-awesome-icon :icon="field.thicon" size="sm" style="pointer-events: none;" v-if="field.thicon"/>
        <template v-else-if="field.label">{{ field.label }}</template>
        <div class="float-right" v-if="field.type === 'BOOL' && field.hasOwnProperty('stateintable')">
            <b-form-checkbox :indeterminate="checkbox_indeterminate" v-model="checkbox_state"
                             v-on:change="changeCheckBox"/>
        </div>
    </th>
    <th v-else class="clickable-header" :style="styleObject"
        v-on:click.self="$emit('toggle-sort', field.name)">
        <font-awesome-icon :icon="['fas', 'sort-up']" size="sm" class="mr-2" style="pointer-events: none;" v-if="field.name === pageInfo.sort_by && pageInfo.sort_type === 'asc'"/>
        <font-awesome-icon :icon="['fas', 'sort-down']" size="sm" class="mr-2" style="pointer-events: none;" v-else-if="field.name === pageInfo.sort_by && pageInfo.sort_type === 'desc'"/>
        <font-awesome-icon :icon="['fas', 'sort']" size="sm" class="mr-2" style="pointer-events: none;" v-else/>
        <font-awesome-icon :icon="field.icon" size="sm" style="pointer-events: none;" v-if="field.icon"/>
        <template v-else-if="field.label">{{ field.label }}</template>
        <div class="float-right" v-if="field.type === 'BOOL' && field.hasOwnProperty('stateintable')">
            <b-form-checkbox :indeterminate="checkbox_indeterminate" v-model="checkbox_state"
                             v-on:change="changeCheckBox"/>
        </div>
    </th>
</template>

<script>
export default {
    name: "CrudTableHeader",
    props: ["field", "pageInfo"],
    data() {
        return {
            checkbox_state: this.field.stateintable !== 2,
            checkbox_indeterminate: this.field.stateintable === 0,
        }
    },
    computed: {
        styleObject: function () {
            let styleObject = '';
            if (this.field.thstyle) {
                styleObject = this.field.thstyle;
            } else {
                switch (this.field.type) {
                    case 'ID':
                        styleObject = 'width: 30px;';
                        break;
                    case 'BOOL':
                        styleObject = 'width: 90px;';
                        break;
                    case 'NUMERIC':
                        styleObject = 'width: 100px;';
                        break;
                }
            }
            return styleObject;
        },
    },
    methods: {
        changeCheckBox() {
            switch (this.field.stateintable) {
                case 0: // indeterminate -> on
                    this.field.stateintable = 1;
                    this.checkbox_state = true;
                    this.checkbox_indeterminate = false;
                    break;
                case 1: // on -> off
                    this.field.stateintable = 2;
                    this.checkbox_state = false;
                    this.checkbox_indeterminate = false;
                    break;
                case 2: // off -> indeterminate
                    this.field.stateintable = 0;
                    this.checkbox_state = true;
                    this.checkbox_indeterminate = true;
                    break;
            }
            this.$emit('toggle-stateintable', this.field)
        }
    }
}
</script>

<style scoped>
</style>